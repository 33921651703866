import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { InsightsView } from "components/views/InsightsView/InsightsView"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { graphql } from "gatsby"
import { useState } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { Capitalize } from "utils/string"

interface InsightsProps {
  data: {
    insights: {
      nodes: ContentfulInsight[]
    }
    latestInsight: {
      nodes: ContentfulInsight[]
    }
    localSearchPages: {
      index: unknown
      store: unknown
    }
  }
  location: WindowLocation
  pageContext: {
    limit: number
    skip: number
    numPages: number
    currentPage: number
    channel: string
  }
}

const InsightFeed = ({ data, location, pageContext }: InsightsProps): JSX.Element => {
  const { numPages, currentPage } = pageContext
  const { index, store } = data.localSearchPages

  const { search } = location

  const query = new URLSearchParams(search).get("s")
  const [searchQuery, setSearchQuery] = useState(query || "")

  const searchResults = useFlexSearch(searchQuery, index, store)

  return (
    <>
      <Seo
        pathname={location.pathname}
        description="Read all about Mesh 2023 and secure your spot here"
        title={Capitalize(process.env.GATSBY_SITE_INSIGHTS_URL || "")}
      />
      <InsightsView
        latestInsight={data.latestInsight.nodes[0]}
        insights={searchQuery ? searchResults : data.insights.nodes}
        currentPage={currentPage}
        numPages={numPages}
        paginationBase={process.env.GATSBY_SITE_INSIGHTS_URL || ""}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </>
  )
}

export const query = graphql`
  query paginatedInsightFeed($skip: Int!, $limit: Int!, $channel: String!) {
    localSearchPages {
      index
      store
    }
    latestInsight: allContentfulNews(
      filter: {
        hidden: { ne: true }
        categories: {
          elemMatch: { metadata: { tags: { elemMatch: { contentful_id: { eq: $channel } } } } }
        }
        metaNoIndexNoFollow: { ne: true }
      }
      sort: { fields: publishDate, order: DESC }
      limit: $limit
    ) {
      nodes {
        ...ContentfulNews
      }
    }
    insights: allContentfulNews(
      filter: {
        hidden: { ne: true }
        categories: {
          elemMatch: { metadata: { tags: { elemMatch: { contentful_id: { eq: $channel } } } } }
        }
        metaNoIndexNoFollow: { ne: true }
      }
      sort: { fields: publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ContentfulNews
      }
    }
  }
`

export default InsightFeed
